import React, { useEffect, useState } from 'react';
import { t } from 'src/i18n/config';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { getUserInfo } from '../../services/user.service';
import SelectPatientsPage, {
  PAGE_NAME as SelectPatientsPageName,
  prepareProps as preparePatientsProps,
} from 'components/appointments/HisBookingSelectPatientsPage';
import FailedPage, {
  PAGE_NAME as FailedPageName,
  prepareProps as prepareFailedProps,
} from 'components/appointments/FailedPage';
import PinMedHisBookingPage, {
  PAGE_NAME as PinMedHisBookingPageName,
  prepareProps as preparePinMedHisBookingProps,
} from 'src/components/appointments/PinMedHisBookingPage';

const stepMap = {
  [FailedPageName]: { page: FailedPage, func: prepareFailedProps },
  [SelectPatientsPageName]: {
    page: SelectPatientsPage,
    func: preparePatientsProps,
  },
  [PinMedHisBookingPageName]: {
    page: PinMedHisBookingPage,
    func: preparePinMedHisBookingProps,
  },
};

const HisBookingCreatePage = ({
  location = {},
  vendor,
  hisBookingIframeUrl,
}) => {
  const { state = {} } = location;
  const [props, setProps] = useState(null);
  const [step, setStep] = useState(
    (state && state.step) || SelectPatientsPageName
  );
  const [lastState, setlastState] = useState({});
  const [isPreparingProps, setIsPreparingProps] = useState(false);
  const { page: Page } = stepMap[step];

  const handleStepChange = (nextStep, lastState) => {
    setStep(nextStep);
    setlastState(lastState);
  };

  useEffect(() => {
    setIsPreparingProps(true);
    const { func } = stepMap[step];
    func(lastState)
      .then((props) => setProps(props))
      .catch((e) =>
        handleStepChange(FailedPageName, { message: JSON.stringify(e) })
      )
      .finally(() => setIsPreparingProps(false));
  }, [step, lastState]);

  useEffect(() => {
    // 當使用者沒有病患資料時，導向新增病患頁面，並在新增病患後導回預約頁面
    getUserInfo().then((user) => {
      if (!user?.patients?.length) {
        const currentUrl = window.location.href;
        const goBack = `${currentUrl.search ? '&' : '?'}goBack=true`;
        window.location.replace(
          currentUrl.replace('/appointments/create', '/users/self') + goBack
        );
      }
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>{t('onlineAppointment')}</title>
      </Helmet>
      <Page
        {...props}
        loading={props == null}
        isPreparingProps={isPreparingProps}
        onChangeStep={handleStepChange}
        vendor={vendor}
        hisBookingIframeUrl={hisBookingIframeUrl}
      ></Page>
      {/* preload iframe content */}
      <iframe src={hisBookingIframeUrl} style={{ display: 'none' }} />
    </Layout>
  );
};

export default HisBookingCreatePage;
