import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import AppointmentsCreatePage from 'pages/appointments/create';
import usePages from 'src/customHooks/pages';
import HisBookingCreatePage from 'pages/hisBooking/create';
import { getOpenApiFeatureToggles } from 'src/services/client.service';
import { isOpenApi } from 'src/services/utils';
import { fetchOpenApiAuthToken } from 'src/services/user.service';
import initPages from 'src/lib/pages';
import Loading from 'src/components/1TalkDesign/Loading';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export default function Home(props) {
  const { message, token, vendor } = usePages();
  const [isLoadingInit, setIsLoadingInit] = useState(true);
  const [hisBookingIframeUrl, setHisBookingIframeUrl] = useState('');
  // #NOTES: 用 iframe url 是不是空字串來判斷是不是 HIS booking flow
  const isHisBookingFlow = hisBookingIframeUrl !== '';

  const renderAppointmentsCreatePage = () => {
    if (isLoadingInit) {
      return (
        <LoadingWrapper>
          <Loading color="rgb(0, 109, 177)"></Loading>
        </LoadingWrapper>
      );
    }

    if (isHisBookingFlow) {
      return (
        <HisBookingCreatePage
          {...props}
          {...message}
          vendor={vendor}
          hisBookingIframeUrl={hisBookingIframeUrl}
        />
      );
    }

    return <AppointmentsCreatePage {...props} {...message} vendor={vendor} />;
  };

  useEffect(() => {
    const init = async () => {
      if (!token) {
        return;
      }
      try {
        let openApiToken = null;
        if (!isOpenApi()) {
          const { liffId, userId } = initPages();
          openApiToken = await fetchOpenApiAuthToken({ userId, liffId });
        }
        const result = await getOpenApiFeatureToggles({ openApiToken });
        setHisBookingIframeUrl(result.NewLiffUrl);
      } finally {
        setIsLoadingInit(false);
      }
    };

    init();
  }, [token]);

  if (token) {
    return (
      <LineContext.Provider value={message}>
        {renderAppointmentsCreatePage()}
      </LineContext.Provider>
    );
  }
  return null;
}
