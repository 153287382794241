import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HorizontalTwoActionFooter from '../Footer/HorizontalTwoActionFooter';
import Menu from '../1TalkDesign/menu';
import theme from '../../theme';
import { Button } from '../1TalkDesign/Buttons';
import CreateMember from '../users/members/CreateMember';
import { PAGE_NAME as NEXT_PAGE } from './PinMedHisBookingPage';
import { getUserInfo } from '../../services/user.service';
import { t } from 'src/i18n/config';
import { isOpenApi } from 'src/services/utils';

const SelectContainer = styled.div`
  margin: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.div`
  font-family: NotoSansCJKtc;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: #202d31;
`;

const CMenu = styled(Menu)`
  margin-top: 8px;
`;

const CreateMemberButton = styled(Button)`
  align-self: flex-end;
  font-weight: normal;
`;

function HisBookingSelectPatients({
  patients: originPatients,
  onChangeStep,
  bindingPhoneNumber,
}) {
  const [createMemberModalVisible, setCreateMemberModalVisible] =
    useState(false);

  const [patients, setPatients] = useState(
    Object.keys(originPatients).map((id) => originPatients[id])
  );

  const [selectedPatient, setSelectedPatient] = useState(patients[0]);

  const handleNext = () => {
    onChangeStep(NEXT_PAGE, {
      patient: originPatients[selectedPatient.id],
      bindingPhoneNumber,
    });
  };

  const handleSelect = (item) => {
    setSelectedPatient(item);
  };

  const showCreateMemberModalVisible = useCallback(() => {
    setCreateMemberModalVisible(true);
  }, []);

  const hideCreateMemberModalVisible = useCallback((newPatient) => {
    if (newPatient) {
      setPatients((patients) => {
        return [
          ...patients,
          {
            id: `${newPatient.id}`,
            name:
              newPatient.alias != null
                ? `${newPatient.name} (${newPatient.alias})`
                : `${newPatient.name}`,
            value: newPatient.id,
          },
        ];
      });
    }
    setCreateMemberModalVisible(false);
  }, []);

  useEffect(() => {
    setPatients(
      Object.keys(originPatients)
        .map((id) => originPatients[id])
        .map((u) => {
          const { id, name, alias, isSelf } = u;
          return {
            id: `${id}`,
            name: isSelf
              ? `${name} ${t('yourself')}`
              : `${name} ${alias != null ? `(${alias})` : ''}`,
            value: id,
          };
        })
    );
  }, [originPatients]);

  useEffect(() => {
    setSelectedPatient(patients[0]);
  }, [patients]);

  if (createMemberModalVisible) {
    return (
      <CreateMember
        onClickCancel={hideCreateMemberModalVisible}
        onCreate={hideCreateMemberModalVisible}
        bindingPhoneNumber={bindingPhoneNumber}
      ></CreateMember>
    );
  }
  return (
    <React.Fragment>
      <SelectContainer>
        <CreateMemberButton
          customizedfontcolor={theme.common.primary}
          customizedcolor={'transparent'}
          onClick={showCreateMemberModalVisible}
        >
          {t('addFamilyMember')}
        </CreateMemberButton>
        <Title>{t('selectAppointmentTarget')}</Title>
        <CMenu
          items={patients}
          name={selectedPatient ? selectedPatient.name : undefined}
          onClickItem={handleSelect}
        />
      </SelectContainer>
      <HorizontalTwoActionFooter
        rightFunc={handleNext}
        rightText={t('next')}
        rightEndIcon={<i className="ri-arrow-right-s-line"></i>}
      ></HorizontalTwoActionFooter>
    </React.Fragment>
  );
}

HisBookingSelectPatients.propTypes = {
  patients: PropTypes.shape({}),
};

HisBookingSelectPatients.defaultProps = {
  patients: {},
};

export default HisBookingSelectPatients;
export const PAGE_NAME = 'SelectPatients';
export const prepareProps = async (lastState) => {
  const user = await getUserInfo();
  if (user.patients != null) {
    const normpatients = user.patients.reduce((s, t) => {
      s[t.id] = isOpenApi() ? { ...t, phone: user.Phone } : t;
      return s;
    }, {});
    return {
      patients: normpatients,
      bindingPhoneNumber: user.Phone,
      ...lastState,
    };
  }
  return {
    patients: {},
    bindingPhoneNumber: user.Phone,
    ...lastState,
  };
};
