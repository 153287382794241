import React, { useEffect, useMemo } from 'react';

function PinMedHisBookingPage({
  patient,
  bindingPhoneNumber,
  onChangeStep,
  hisBookingIframeUrl,
}) {
  const userInfo = useMemo(() => {
    if (!patient) {
      return {};
    }
    return {
      name: patient.name || '',
      gender: patient.gender || 'M',
      birth: patient.birth || '',
      phone: bindingPhoneNumber,
      idType: patient.idType,
      idNum: patient.idNum,
      openFromLiff: true,
      utm_source: 'line',
      utm_medium: 'referral',
      utm_campaign: 'liff',
    };
  }, [bindingPhoneNumber, patient]);

  const params = new URLSearchParams(userInfo).toString();

  useEffect(() => {
    if (hisBookingIframeUrl) {
      window.location = `${hisBookingIframeUrl}?${params}`;
    }
  }, [hisBookingIframeUrl, params]);

  return null;
}

export default PinMedHisBookingPage;
export const PAGE_NAME = 'PinMedHisBookingPage';
export const prepareProps = async (lastState) => {
  return {
    ...lastState,
  };
};
